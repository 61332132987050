<template>
  <template v-for="item in navItems">
    <RouterLink
      v-if="
        !item.hasOwnProperty('subItems')
        && isUserHasAccessToNavItem(item)
        && ( !item.checkIsVisible || (item.checkIsVisible && item.checkIsVisible(user)) )
      "
      :class='["item",{"mobile": mobile, "desktop": desktop}]'
      :to="item.path"
      tag="div"
      active-class="active"
    >
      {{ item.name }}
    </RouterLink>
    <Menu
      v-else-if="
        item.hasOwnProperty('subItems')
        && isUserHasAccessToNavItem(item)
        && ( !item.checkIsVisible || (item.checkIsVisible && item.checkIsVisible(user)) )"
      :items='item.subItems'
    >
      <template #expander>
        <div :class='["item",{"mobile": mobile, "desktop": desktop}]'>
          {{ item.name }}
        </div>
      </template>
    </Menu>
  </template>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { RouterLink } from 'vue-router';
import Menu from '@/components/Menu.vue';
import navItemsData from './navItems.config';
import useUser from '@/composables/user';

const user = useUser();

function isUserHasAccessToNavItem(navItem) {
  const isHasAccess = (
    (navItem.hasOwnProperty('areaAccessName') &&
      user.hasReadAccessToArea(navItem.areaAccessName)) ||
    !navItem.hasOwnProperty('areaAccessName')
  )
  return isHasAccess;
}

defineProps<{
  mobile?: boolean;
  desktop?: boolean;
}>();

const navItems = reactive(navItemsData);
</script>

<style lang="scss" scoped>
.item.desktop {
  font-size: 16px;
  margin-right: 24px;
  letter-spacing: 0.9px;
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  @include medium-devices-down {
    font-size: 0.8em;
  }
  
  &:hover {
    font-weight: bold;
  }
  &.active {
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    text-underline-position: under;
    font-weight: bold;
  }
}



.item.mobile {
  text-decoration: none;
  color: #ffffffbf;
  margin: 0.3em;
  font-size: 2em;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover, &.active {
    font-weight: bold;
    color: #fffffff2;
  }

  &.active {
    text-decoration: underline;
    text-decoration-color: #ffffff64;
    text-underline-position: under;
  }
}
</style>