export function isDefined(value: any) {
  return (value !== undefined && value !== null);
}

export function doMany(actions) {
  actions.forEach(action => action());
}

export function promptSaveDataToFile(data, fileName) {
  // Inspirer: https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6
  // TODO: think if there's a cleaner way to "prompt save fetch / axios response to file" — N2FEaB8n
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function formatBytes(bytes, decimals = 2) {
  // First got from: ChatGPT conversation "https://chat.openai.com/chat/d3cc689d-679d-4816-92cc-8f339d30807b"
  if (bytes === 0) return '0 Bytes'
  
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}


export function getKeyByValue(object, value) {
  // First got from: https://stackoverflow.com/a/28191966/3503851
  return Object.keys(object).find(key => object[key] === value);
}


// TODO: think about how to make the time format more readable — N2A5ipA4
  // May be including AM/PM
export function currentTimeFormatted() {
  const currenTime = new Date;
  const ct = currenTime;
  const h = ct.getHours();
  const m = ct.getMinutes();
  const s = ct.getSeconds();
  const ms = ct.getMilliseconds();
  const currentTimeStr = `${h}:${m}:${s}.${ms}`;
  return currentTimeStr;
}

export function format24TimeToAMPM(inputTime: string) {
  // formats 24-hour time to 12-hour time (with AM/PM)
  const [hours, minutes, seconds] = inputTime.split(":");
  const parsedHours = Number(hours);
  const ampm = parsedHours >= 12 ? "PM" : "AM";
  const formattedHours = (parsedHours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.padStart(2, "0");
  const formattedSeconds = seconds.padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
}

export function prepareImportedPaths(pathsContainer: any) {
  const isObject = typeof pathsContainer === 'object' && !Array.isArray('object');
  
  let newPathsContainer;
  
  if(isObject) {
    newPathsContainer = {} as any;
    for (const [key, value] of Object.entries(pathsContainer)) {
      const path = value;
      newPathsContainer[key] = prepareImportedPath(path);
    }
  } // TODO: if needed later, add support for arrays

  return newPathsContainer;
}

export function prepareImportedPath(path: string) {
  const newPath = path.replace(/(?<=\/)@fs\//,'');
  return newPath;
}


export function wrapPathsAsCssUrls(pathsContainer: any) {
  const isObject = typeof pathsContainer === 'object' && !Array.isArray('object');
  
  let newPathsContainer;
  
  if(isObject) {
    newPathsContainer = {} as any;
    for (const [key, value] of Object.entries(pathsContainer)) {
      const path = value;
      newPathsContainer[key] = wrapPathAsCssUrl(path);
    }
  } // TODO: if needed later, add support for arrays

  return newPathsContainer; 
}

export function wrapPathAsCssUrl(path: string) {
  const newPath = path.replace(/^(.*)$/,'url($1)');
  return newPath;
}

export function prepareImportedPathsAsCssUrls(pathsContainer: any) {
  const newPathsContainer = prepareImportedPaths(pathsContainer);
  const newPathsWrappedAsCssUrlsContainer = wrapPathsAsCssUrls(newPathsContainer);
  return newPathsWrappedAsCssUrlsContainer;
}



const elapsedTimeSessions = {} as any;   // helper for elapsedTimeSinceLast
export function elapsedTimeSinceLast(key:string) {
  // initiate session if not yet initiated
  if (!elapsedTimeSessions[key]) {
    elapsedTimeSessions[key] = {
      previous: 0,     // elapsed since timeorigin till last measurement
      now: 0,     // elapsed since timeorigin till this measurement
    }
  }

  // set "previous" to the "previous now"
  elapsedTimeSessions[key].previous = elapsedTimeSessions[key].now;

  // set "now" to the current time
  elapsedTimeSessions[key].now = performance.now();

  // finalize and return the elapsed time since last measurement
  const elapsedTimeSinceLast = elapsedTimeSessions[key].now - elapsedTimeSessions[key].previous;

  const elapsedTimeSinceLast_formatted = elapsedTimeSinceLast/1000 < 1
      ? elapsedTimeSinceLast.toFixed(1)+'ms'
      : Math.floor(elapsedTimeSinceLast/1000)+'s '+(elapsedTimeSinceLast%1000).toFixed(1)+'ms';
  
  return elapsedTimeSinceLast_formatted;
}


export async function getImageSize(file: File) {
  // create a new FileReader object
  const reader = new FileReader();

  // create a Promise to resolve when the reader has finished loading the file
  const fileReaderLoadPromise = new Promise(function(resolve, reject) {
    reader.onload = resolve;
    reader.onerror = reject;
  });

  // read the file as a data URL
  reader.readAsDataURL(file);

  // wait for the reader to finish loading the file
  await fileReaderLoadPromise;

  // create a new Image object
  const img = new Image();

  // create a Promise to resolve when the image has finished loading
  const imgLoadPromise = new Promise(function(resolve, reject) {
    img.onload = resolve;
    img.onerror = reject;
  });

  // set the source of the image to the data URL
  img.src = reader.result as string;

  // wait for the image to finish loading
  await imgLoadPromise;

  // get the height and width of the image
  const height = img.height;
  const width = img.width;

  return { height, width };
}


export async function getVideoSizeAndDuration(file: File) {
  // Notes
    // inspirer: https://stackoverflow.com/a/51696339/3503851
  
  const url = URL.createObjectURL(file)
  const video = document.createElement('video')
  video.onloadedmetadata = evt => {
    // Revoke when you don't need the url any more to release any reference
    URL.revokeObjectURL(url)
    console.log(video.videoWidth, video.videoHeight)
  }
  video.src = url
  video.load() // fetches metadata



  // const url = URL.createObjectURL(file)
  // const video = document.createElement('video')
  // const videoLoadPromise = new Promise(function(resolve, reject) {
  //   video.onloadedmetadata = resolve;
  //   video.onerror = reject;
  //   video.load() // fetches metadata
  // });

  // video.src = url
  // await videoLoadPromise;

  // URL.revokeObjectURL(url);
  const height = video.videoHeight;
  const width = video.videoWidth;
  const duration = 0 /* video.duration */;
  
  return { height, width, duration };
}

export function isImage(file: File) {
  const fileType = file.type;
  return fileType.startsWith('image/')
}

export function isVideo(file: File) {
  const fileType = file.type;
  return fileType.startsWith('video/')
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export function joinPaths(...segments: string[]) {
  const nonEmptySegments = segments.filter(segment => segment);
  const joinedPath = nonEmptySegments.join('/');
  return joinedPath.replace(/\/+/g, '/');
}