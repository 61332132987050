import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { addPlayerMetadata, deleteMetadata, getPlayerById, updatePlayer } from "@/helpers/api/player/player";
import _pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";

export default function usePlayerEditorForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]
  const hasChanges = ref (false)
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const playerId = ref("");
  const selectedPlayer = ref ()
  const validationSchema = yup.object({
    name: yup.string().label('Name').required()
  });
  
  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema });
  const fieldsToSubmit = computed(() => {
    const { name,description } = values;
    return {
      name,
      description
    };
  });

  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);

 async function loadPlayer(itemId: any){
  playerId.value = itemId 
  selectedPlayer.value = (await getPlayerById (itemId))[0];
 fillInitialValues(selectedPlayer.value)
  
 }

 watch(fieldsToSubmit, (newFields,oldFields,) => {

  if (oldFields.name !=null)
  { 
    hasChanges.value =true 
  }
  if (hasChanges.value)
  {  
    onBeforeLoad() ; 
  }else { 
    removeBeforeLoad();
  }
})

watch(hasChanges, (newValue,oldValue,) => {
  if (newValue)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})

function onBeforeLoad () {
  window.addEventListener('hashchange', showAlert);
  window.addEventListener('beforeunload',showAlert 
  );
}
function removeBeforeLoad () {
  window.removeEventListener('hashchange', showAlert);

  window.removeEventListener('beforeunload',showAlert)

}

function showAlert (event :any ) {
  event.returnValue = 'You have unfinished changes!';
}
 function fillInitialValues (player : any ) {
  values.name = player.name
  values.description = player.description
  hasChanges.value = false 
 }
 
  async function handleSubmitValid() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      //-7200000
    //  fieldsToSubmit.value.endDate = new Date (fieldsToSubmit.value.endDate).getTime() as any 
    //  fieldsToSubmit.value.startDate = new Date (fieldsToSubmit.value.startDate).getTime() as any 
      let updateModel = fillSubmitModel()  ;
      const submissionResponse = await updatePlayer( playerId.value,
        updateModel
      );
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        isSuccess.value = true;
        hasChanges.value =false
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  function getPlayerModelCleaned_forSubmission(model: any) {
    const modelCleaned = cloneDeep(model);
    // clean player model from unneeded fields
    const campaigns = modelCleaned.campaigns;
    modelCleaned.campaigns = campaigns.map((item: any) => {
      return _pick(item, ['id']);
    });
    return modelCleaned;
  }

  function fillSubmitModel () {
    const newModel = getPlayerModelCleaned_forSubmission(selectedPlayer.value);
    newModel.name = fieldsToSubmit.value.name
    newModel.description = fieldsToSubmit.value.description
    return newModel
  }
  async function addTag (newTag:Object) {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      //-7200000
    //  fieldsToSubmit.value.endDate = new Date (fieldsToSubmit.value.endDate).getTime() as any 
    //  fieldsToSubmit.value.startDate = new Date (fieldsToSubmit.value.startDate).getTime() as any 
      const submissionResponse = await addPlayerMetadata( playerId.value,
        newTag
      );
      isLoading.value = false;
      if (submissionResponse.length>0)
      {
        selectedPlayer.value.metaDatas.push(...submissionResponse)
      }
      
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }
  async function deleteTag (tagId : number ,index :number  ) {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      //-7200000
    //  fieldsToSubmit.value.endDate = new Date (fieldsToSubmit.value.endDate).getTime() as any 
    //  fieldsToSubmit.value.startDate = new Date (fieldsToSubmit.value.startDate).getTime() as any 
      const submissionResponse = await deleteMetadata( tagId,
      );
      isLoading.value = false;
      if (submissionResponse.isSuccessful ==true)
      {
        selectedPlayer.value.metaDatas.splice(index,1)
      }
      
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
    
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    loadPlayer,
    hasChanges,
    playerId,
    removeBeforeLoad,
    selectedPlayer ,
    addTag,
    deleteTag

  };
}
