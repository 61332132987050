import { reactive, nextTick } from "vue";
import { getCampaignById } from "@/helpers/api/campaign/campaign";
import { useHasUnsavedChangesHelpers } from "@/composables/utils/hasUnsavedChangesHelpers";

const basicInfo = reactive({
  id: "",
  name: "",
  startDate: "",
  endDate: "",
  isEnabled: "",
  approvalState: 1,
  sharingLevel: 1
});
const players = reactive<any[]>([]);
const playlists = reactive<any[]>([]);

export default function useCampaign() {
  const {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized,
  } = useHasUnsavedChangesHelpers({
    statesToWatchForChanges: [basicInfo, players, playlists],
  });


  async function loadCampaign() {
    isLoading.value = true;
    const campaign = await getCampaignById(basicInfo.id);
    fillInitialValues(campaign[0]);
    await nextTick();
    isDataInitialized.value = true;
    isLoading.value = false;
  }

  function fillInitialValues(campaign: any) {
    // fill initial basic info data
    Object.assign(basicInfo, {
      name: campaign.name,
      endDate: new Date(campaign.endDate).toISOString().slice(0, 10),
      isEnabled: campaign.isEnabled,
      startDate: new Date(campaign.startDate).toISOString().slice(0, 10),
      // approvalState: campaign.approvalState,
      approvalState: 1,
      // sharingLevel: campaign.sharingLevel,
      sharingLevel: 1,
      // TODO (May-2023): to think about removing this "approvalState" and "sharingLevel" forced values to be 1.
      //   This is added because of the old buggy state where "Playlists" and "Campaigns" were saved with "approvalState" and "sharingLevel" of 0.
      //   So, to avoid fix the issue, resaving the playlist / campaign with these forced values solves the problem.
    });

    // fill campaign items (playlists) initial data
    playlists.splice(0, playlists.length);
    campaign.items.forEach((item: any) => {
      // assign itemKey
      // - itemKey will be used as the list item's vnode key, having it unique is important in order to avoid
      //   rendering issues.
      // - since "campagin item id" is unique for each "campaign item" (playlist), it's okay to use as the
      //   itemKey for retrieved (previously-saved) campaign items
      item.itemKey = item.id;

      playlists.push(item);
    });

    // fill players initial data
    players.splice(0, players.length);
    campaign.players.forEach((item: any) => {
      players.push(item);
    });
  }

  function addPlaylist(playlistItems: []) {
    playlistItems.forEach((item: any) => {
      const newItem = {
        playlist: item,
        startTime: "00:00:00",
        endTime: "23:59:59",
        startDate: null,
        endDate: null,
        weekdays: 127,
        itemKey: `${item.id}-${new Date().getTime()}`,
        // - itemKey is set like this because newly added "campagin item" doesn't have id yet (not like retrieved / previously-saved campaign items).
        //   - This generates a unique composite id of playlist id and the time it was added.
      };
      playlists.push(newItem);
    });
  }

  function addPlayer(playerList: []) {
    playerList.forEach((player: any) => {
      if (players.filter((item) => item.id == player.id).length == 0) {
        players.push(player);
      }
    });
  }

  function reorderPlaylist(oldIndex: number, newIndex: number) {
    if (newIndex > -1 && newIndex < playlists.length) {
      const selectedPlaylist = playlists[oldIndex];
      playlists.splice(oldIndex, 1);
      playlists.splice(newIndex, 0, selectedPlaylist);
    }
  }

  function removePlaylist(index: number) {
    playlists.splice(index, 1);
  }

  function removePlayer(index: number) {
    players.splice(index, 1);
  }

  return {
    basicInfo,
    playlists,
    players,
    loadCampaign,
    hasUnsavedChanges,
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    reorderPlaylist,
    removePlaylist,
    removePlayer,
    addPlaylist,
    addPlayer,
    isLoading
  }
}