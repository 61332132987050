<template>
  <div class="edit-campaign" v-if="!isLoading">
    <h1 class="title">
      CAMPAIGN Editor
      <img src="../../components/icons/OldThemeIconRed/images/refresh-button.png" @click="loadCampaign">
    </h1>
    <StatusBar :errorMessage="errorMessage" />
    <div class="form-fields separetor py-4 ">
      <div class="main-fields">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup col-md-4 col-12">
          <label for="name">Name &#9733;</label>
          <TextField v-model="basicInfo.name" width="100%" />
        </div>
        <div class="formGroup col-md-4 col-12">
          <label for="Start Date">Start Date</label>
          <DateField v-model="basicInfo.startDate" width="100%" />
        </div>
  
        <div class="formGroup col-md-4 col-12">
          <label for="End Date">End Date </label>
          <DateField v-model="basicInfo.endDate" width="100%" />
        </div>
  
        <div class="formGroup col-md-4 col-12">
          <label for="Active">Active?</label>
          <input class="form-check-input" type="checkbox" v-model="basicInfo.isEnabled" id="Active">
        </div>
      </div>
      <CreateAnotherCampaignButton />
    </div>
    <div class=" mb-5 separetor pt-3">
      <div class="top-header mt-5 ">
        <h5 class="sub-title " :class="{ 'd-inline': playlists.length > 0 }"> Playlists
        </h5>
        <div class="d-flex" v-if="playlists.length > 0">
          <AddPlaylistDialog :showBtn=true :campaignTitle="basicInfo.name" @save="addPlaylist($event)" />
        </div>
      </div>

      <!-- <a class="add-link" v-if="playlists.length > 0">
    </a> -->
      <div class="mt-3 add-info" v-if="playlists.length == 0"> Campaigns need one or more playlists to run -
        <a class="add-link">
          <AddPlaylistDialog :campaignTitle="basicInfo.name" :showBtn=false @save="addPlaylist($event)" />
        </a>
      </div>
      <div v-if="playlists.length > 0">
        <table>
          <thead>
            <tr class="table-separetor">
              <th>#</th>
              <th>Name</th>
              <th>Recurrence</th>
              <th>Time Period</th>
              <th>Size</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <draggable :list="playlists" group="campaignPlaylists" tag="tbody" item-key="itemKey">
            <template #item="{ element, index }">
              <tr>
                <th>{{ index + 1 }}</th>
                <td class="font-bold tb-link">
                  <RouterLink :to="{ name: 'playlist-editor', params: { id: element.playlist?.id } }">
                    {{ element.playlist?.name }}
                  </RouterLink>
                </td>
                <td class="font-bold tb-link">
                  <EditRecurrenceDialog :playlist="element" @save="saveRecurrance($event, index)">
                  </EditRecurrenceDialog>
                </td>
                <td>{{ isPlaylistHasCustomRecurrence(element) ? `${format24TimeToAMPM(element.startTime)} -
                  ${format24TimeToAMPM(element.endTime)}` : '' }}</td>
                <td>{{ formatBytes(element.playlist?.size) }}</td>
                <td>
                  <div class="d-flex">
                    <OldThemeIcon @click="reorderPlaylist(index, index + 1)" name="bullet-down" class="icon mx-2" />
                    <OldThemeIcon @click="reorderPlaylist(index, index - 1)" name="bullet-up" class="icon" />
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="view-Icon">
                      <OldThemeIcon name="eye" class="icon" />
                      <div class="playlist-asset">
                        <img v-for="(item, index) of element.playlist?.items" :key="index" width="140" height="120"
                          :src="item.asset.previewUri" />
                      </div>
                    </div>
                    <OldThemeIcon @click="editPlaylist(element.playlist?.id)" name="edit" class="icon mx-2 " />
                    <OldThemeIcon @click="removePlaylist(index)" name="delete" class="icon" />
                  </div>
                </td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>
    <div class="mt-5">
      <div class="top-header mt-5">
        <h5 class="sub-title " :class="{ 'd-inline': players.length > 0 }"> Players</h5>
        <div class="d-flex" v-if="players.length > 0">
          <AddPlayertDialog :showBtn="true" :campaignTitle="basicInfo.name" @save="addPlayer($event)">
          </AddPlayertDialog>
        </div>
      </div>
      <div class="mt-3 add-info" v-if="players.length == 0"> Campaigns need to be assigned to one or more physical media
        players -
        <a class="add-link">
          <AddPlayertDialog :showBtn="false" :campaignTitle="basicInfo.name" @save="addPlayer($event)">
          </AddPlayertDialog>
        </a>
      </div>
    </div>
    <div v-if="players.length > 0">
      <table>
        <thead>
          <tr class="table-separetor">
            <th>Name</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(player, itemIndex) of players"
            :key="player.id"
          >
            <td class="font-bold tb-link" v-on:click="editPlayer(player.id)">
              <a>
                {{ player.name }}
              </a>
            </td>
            <td class="font-bold tb-link" v-on:click="editLocation(player.location?.id)">
              <a>
                {{ player.location?.name }}
              </a>
            </td>
            <td>
              <div class="d-flex">
                <OldThemeIcon @click="editPlayer(player.id)" name="edit" class="icon" />
                <OldThemeIcon @click="removePlayer(itemIndex)" name="delete" class="icon" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <FloatingFooterButtons>
    <div class="flex flex-row-reverse gap-2">
      <SaveCampaignButton ref="saveCampaignButtonRef" />
      <ExitButton v-bind="{ hasUnsavedChanges, unsetHasUnsavedChanges }" @exit="onExit" @saveAndExit="onSaveAndExit" />
    </div>
  </FloatingFooterButtons>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import DateField from "@/components/form-fields/DateField.vue";
import TextField from "@/components/form-fields/TextField.vue";
import useSaveCampaignFormHelpers from "./composables/saveCampaignFormHelpers";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import AddPlaylistDialog from './AddPlaylist/AddPlaylistDialog.vue';
import AddPlayertDialog from './AddPlayer/AddPlayerDialog.vue'
import OldThemeIcon from '../../components/icons/OldThemeIcon.vue'
import { formatBytes, format24TimeToAMPM } from '@/helpers/utils';
import EditRecurrenceDialog from './EditRecurrence/EditRecurrenceDialog.vue'
import useCampaign from './composables/campaign';
import FloatingFooterButtons from '@/components/layouts/FloatingFooterButtons.vue';
import SaveCampaignButton from './SaveCampaignButton/SaveCampaignButton.vue';
import ExitButton from '@/components/Dialog/ButtonsWithDialog/ExitButton/ExitButton.vue';
import CreateAnotherCampaignButton from './CreateAnotherCampaignButton/CreateAnotherCampaignButton.vue'
import sharedRefs from './sharedRefs';
import router from '@/router';
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import draggable from "vuedraggable";

const route = useRoute()
const { saveCampaignButtonRef } = sharedRefs;

const {
  basicInfo,
  playlists,
  players,
  loadCampaign,
  isLoading,
  reorderPlaylist,
  removePlayer,
  removePlaylist,
  addPlaylist,
  addPlayer,
  hasUnsavedChanges,
  unsetHasUnsavedChanges,
  initializeHasUnsavedChangesWatcher,
} = useCampaign();

const { errorMessage } = useSaveCampaignFormHelpers();

initializeHasUnsavedChangesWatcher();

onBeforeMount(() => {
  basicInfo.id = route.params.id as string;
  loadCampaign()
})

onBeforeRouteUpdate(async (newRoute) => {
  if(newRoute.name === "campaign-editor") {
    basicInfo.id = newRoute.params.id as string;
    await loadCampaign();
  }
})

function onExit() {
  router.push({ path: '/campaigns' })
}

async function onSaveAndExit() {
  await saveCampaignButtonRef.value.savePlaylist();
  router.push({ path: '/campaigns' })
}

function editPlayer(id: number) {
  router.push({ name: 'player-editor', params: { id: id } });

}
function editPlaylist(id: number) {
  router.push({ name: 'playlist-editor', params: { id: id } });

}
function editLocation(id: number) {
  router.push({ name: 'location-editor', params: { id: id } });

}
function saveRecurrance(item: any, index: number) {
  playlists[index].startTime = item.startTime
  playlists[index].weekdays = item.weekdays
  playlists[index].endTime = item.endTime
  playlists[index].startDate = item.startDate
  playlists[index].endDate = item.endDate
}

function isPlaylistHasCustomRecurrence(playlist: any) {
  const isAllday = playlist.startTime === "00:00:00" && playlist.endTime === "23:59:59";
  const isEveryday = playlist.weekdays === 127;
  const isDateSameAsCampaign = playlist.startDate == null && playlist.endDate == null;
  return !(isAllday && isEveryday && isDateSameAsCampaign);
}
</script>

<style lang="scss" scoped>
.editor-btn-exit {
  position: fixed;
  bottom: 0px;
  right: 70px;
}

.editor-btn {
  position: fixed;
  bottom: 34px;
  width: fit-content;
  right: 0px;
  max-width: 1200px;
  text-align: right;
  z-index: 5;
  padding-bottom: 10px;
  padding-top: 7px;
}

.status-bar-container {
  margin-bottom: 1rem;
}

.create-slide-form {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}

.separetor {
  border-top: 1px solid #e6e6e6;
}

.form-fields {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;

  .main-fields {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
  }
  
  @include medium-devices-down {
    flex-direction: column;
  }
}

.formGroup {
  display: flex;
  align-items: center;

  .select {
    width: 60%;
    margin: 0;
  }

  label {
    display: inline-block;
    width: 70px;
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

h1.title {
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;

  @include theme-old {
    color: #252949;
  }

  @include theme-new {
    color: #252949;
  }

  letter-spacing: 2px;
}

.sub-title {
  font-size: 1.5em;
  font-weight: bold;

  @include theme-old {
    color: #252949;
  }

  @include theme-new {
    color: #252949;
  }

  letter-spacing: 2px;
}

.add-link {
  font-weight: bold;
  color: #252949;
  cursor: pointer;
}

.add-link:hover {
  text-decoration: underline;
  color: #93A5AE;
}

.add-info {
  font-family: Arial;
  color: #666666;
  font-size: 16px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-separetor {
  border-bottom: 1px solid #e6e6e6;

}

.tb-link,
.tb-link a {
  cursor: pointer;
  color: #252961;
  text-decoration: none;
}

.tb-link :hover {
  text-decoration: underline;
  color: #93A5AE;
}

.edit-campaign {
  margin-bottom: 80px;
}

.playlist-asset {
  display: none;
  position: absolute;
  right: 0px;
  max-width: 400px;
  overflow-x: auto;
  z-index: 4;

}

.view-Icon {
  position: relative;
}

.view-Icon:hover .playlist-asset {
  display: flex
}
</style>
