<template>
  <Transition name="fade">
    <div v-if="inlineDots" class="fade-item">
      <template v-if="isLoading">
        ...
      </template>
      <template v-else>
        <slot v-if="$slots.loaded" name="loaded">
        </slot>
        <slot v-else>
        </slot>
      </template>
    </div>
    <div ref="containerRef" v-else class="fade-item">
      <template v-if="isLoading">
        <div v-if="$slots.loading">
          <slot name="loading" />
        </div>
        <div v-else class="content-loading-skeleton">
          <LoadingSkeleton :containerRef="containerRef" :loading-message="loadingMessage" />
        </div>
      </template>
      <template v-else>
        <slot v-if="$slots.loaded" name="loaded"></slot>
        <slot v-else></slot>
      </template>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import LoadingSkeleton from './LoadingSkeleton.vue';
const containerRef = ref<HTMLDivElement>();

defineProps(['isLoading', 'inlineDots', 'width', 'height', 'loadingMessage']);

</script>

<style lang="scss" scoped>
.content-loading-skeleton {
  width: 100%;
  height: 100%;
  /* margin: 1em; */
}

.fade-item {
  opacity: 1;
}

</style>

<style>
/* TODO: to investigate why transition animations isn't working at all — N2942c9g */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>