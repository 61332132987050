<!--
  TODO (cleaning): Somehow similar components ...1 to refine / clean potential redundancy — N3EIg6Cq
    ...1:
      AddPlaylistDialog.vue: @\views\CampaignEditorView\AddPlaylist\AddPlaylistDialog.vue
-->

<template>
<Dialog>
  <template #expander="{openModal}">
    <div @click="openModal">
      <slot name="expander"></slot>
    </div>
  </template>
  <!-- TODO: think about refactoring "Dialog" to rename "dialog.title" to just "title" and so on with "content, title-after, ..." — N3EHu373 -->
  <template #dialog.title>Add Image</template>
  <template #dialog.title-after>
    <div class="button">
      <UploadMediaButtonWithDialog />
    </div>
  </template>
  <template #dialog.content="{closeModal, closeModalAfter}">
    <ImagesListerWithSelect />
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="closeModal" secondary />
      <ButtonCustomized
        :disabled="selectedItems.length === 0"
        label="Add"
        @click="doMany([changeImageOfImageSprite.bind(null, selectedSprite, selectedItems[0]), closeModal])"
      />
    </div>
  </template>
  <template #dialog.subtitle>
    {{ slide.basicInfo.name }} <!-- TODO: remove the unnecessary double quotes arount slide name -->
  </template>
  
</Dialog>

</template>

<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ImagesListerWithSelect from '@/views/MediaEditorView/SlideEditor/PropertiesPanesRight/TopMenu/ToolsMenu/AddImageDialog/ImagesListerWithSelect/ImagesListerWithSelect.vue';
  // TODO: think about moving "ImagesListerWithSelect" to a more generic place if it's cleaner this way — N3FHid10
    // #architecture #cleaner-code
    // For now seems it's okay like this
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import UploadMediaButtonWithDialog from '@/views/MediaListView/TopBarMainButtons/UploadMedia/UploadMediaButtonWithDialog.vue';
import { doMany } from '@/helpers/utils';

import { log } from '@/helpers/utils';

const {
  selectedItemsIds,
  selectedItems,
  clearItemsSelection
} = useListSelection({
  clearSelectionTriggerElement: document.body
});

const { selectedSprite } = useSlide();

const slide = useSlide();

const { changeImageOfImageSprite } = slide;

</script>

<style lang="scss" scoped>
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.button :deep(.button) {
  font-size: 1rem;
}
</style>