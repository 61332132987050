<template>
  <Dialog :expanderClass="'d-inline'" ref="recurranceModal">
    <template #expander="{ openModal }">
      <a @click="openModal"> {{ form.type == 0 ? 'All Day, Every Day' : "Custom Recurrence" }}</a>

    </template>
    <template #dialog.title>
      Edit Recurrence
      <h6 class="mb-3">
        {{ playlist.playlist.name }}
      </h6>
    </template>
    <template #dialog.content="{ closeModal, closeModalAfter }">


      <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
      <div>
        <div class="d-flex">
          <label>Recurrence: </label>
          <div class="mx-2">
            <input class="" type="radio" name="inlineRadioOptions" v-model="form.type" id="inlineRadio1" :value="0">
            <label class="" for="inlineRadio1">All Day, Every Day</label>
          </div>
          <div class="">
            <input class="" type="radio" name="inlineRadioOptions" v-model="form.type" id="inlineRadio2" :value="1">
            <label class="" for="inlineRadio2">Custom Period</label>
          </div>
        </div>
        <div v-if="form.type == 1">
          <table>
            <thead>
              <tr class="table-separetor">
                <th>Days</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-top">
                  <div class="">
                    <input class="" type="checkbox" id="everday" v-model="form.everday" @change="onEverdayChange()">
                    <label class="" for="everday">
                      Every Day
                    </label>
                  </div>
                  <div class="mx-3">
                    <div class="">
                      <input class="" type="checkbox" id="monday" v-model="form.monday">
                      <label class="" for="monday">
                        Monday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="tuesday" v-model="form.tuesday">
                      <label class="" for="tuesday">
                        Tuesday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="wednesday" v-model="form.wednesday">
                      <label class="" for="wednesday">
                        Wednesday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="thursday" v-model="form.thursday">
                      <label class="" for="thursday">
                        Thursday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="friday" v-model="form.friday">
                      <label class="" for="friday">
                        Friday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="saturday" v-model="form.saturday">
                      <label class="" for="saturday">
                        Saturday(s)
                      </label>
                    </div>
                    <div class="">
                      <input class="" type="checkbox" id="sunday" v-model="form.sunday">
                      <label class="" for="sunday">
                        Sunday(s)
                      </label>
                    </div>
                  </div>
                </td>

                <td class="align-top">
                  <!-- campaign item time -->
                  <div class="">
                    <input class="" type="checkbox" id="allDay" v-model="form.allDay">
                    <label class="" for="allDay">
                      All Day
                    </label>
                  </div>
                  <div class="d-flex">
                    <div class="mr-2">
                      <label> Start time </label>
                      <TimeField v-model="form.startTime" width="100%" :disabled="form.allDay" step=1 />
                    </div>
                    <div>
                      <label> End time </label>
                      <TimeField v-model="form.endTime" width="100%" :disabled="form.allDay" step=1 />
                    </div>
                  </div>
                  <!-- campaign item date -->
                  <div class="mt-4">
                    <b>Date</b>
                    <div>
                      <input class="" type="checkbox" id="dateSameAsCampaign" v-model="form.dateSameAsCampaign">
                      <label class="" for="dateSameAsCampaign">
                        Same as campaign
                      </label>
                    </div>
                    <div class="d-flex">
                      <div class="mr-2">
                        <label> Start Date </label>
                        <DateField v-model="form.startDate" width="100%" :disabled="form.dateSameAsCampaign" step=1 />
                      </div>
                      <div>
                        <label> End Date </label>
                        <DateField v-model="form.endDate" width="100%" :disabled="form.dateSameAsCampaign" step=1 />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>



        <div class="form-buttons">
          <ButtonCustomized label="Save" @click="onSave" />
        </div>
      </div>

    </template>
  </Dialog>
</template>
    
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
import PlaylistsLister from '../../PlaylistsListView/PlaylistsLister/PlaylistsLister.vue';
import { onMounted, ref, watch } from 'vue';
import TimeField from '@/components/form-fields/TimeField.vue'
import DateField from '@/components/form-fields/DateField.vue'
const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const props = defineProps({
  'campaignTitle': {
    type: String,
    default: ""
  },
  'playlist': {
    type: Object,
    default: null
  }
})
const recurranceModal = ref()
const form = ref({
  type: 0,
  everday: true,
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  startTime: "00:00:00",
  endTime: "23:59:59",
  allDay: true,
  startDate: null,
  endDate: null,
  dateSameAsCampaign: true
})
onMounted(() => {
  fillModel();

})
watch(form.value, (newValue,oldValue,) => {
  if (!newValue.friday ||!newValue.saturday ||
  !newValue.sunday ||!newValue.monday ||!newValue.tuesday ||!newValue.wednesday || !newValue.thursday  )
  {
    form.value.everday= false 
  }
  if (newValue.friday && newValue.saturday &&
   newValue.sunday &&newValue.monday && newValue.tuesday && newValue.wednesday
   && newValue.thursday  )
  {
    form.value.everday= true 
  }
})
function onEverdayChange() {
  if (form.value.everday) {
    form.value.monday = true
    form.value.tuesday = true
    form.value.wednesday = true
    form.value.thursday = true
    form.value.friday = true
    form.value.sunday = true
    form.value.saturday = true
  } else {
    form.value.monday = false
    form.value.tuesday = false
    form.value.wednesday = false
    form.value.thursday = false
    form.value.friday = false
    form.value.sunday = false
    form.value.saturday = false
  }
}

function fillModel() {
  form.value.everday = props.playlist.weekdays == 127 ? true : false
  form.value.startTime = props.playlist.startTime.slice(0, 8)
  form.value.endTime = props.playlist.endTime.slice(0, 8)
  form.value.startDate = props.playlist.startDate == null ? null : new Date(props.playlist.startDate+" UTC").toISOString().slice(0,10);
  form.value.endDate = props.playlist.endDate == null ? null : new Date(props.playlist.endDate+" UTC").toISOString().slice(0,10);
  if (!form.value.everday) {
    form.value.saturday = (props.playlist.weekdays & 64) > 0 ? true : false
    form.value.sunday = (props.playlist.weekdays & 1) > 0
    form.value.monday = (props.playlist.weekdays & 2) > 0
    form.value.tuesday = (props.playlist.weekdays & 4) > 0
    form.value.wednesday = (props.playlist.weekdays & 8) > 0
    form.value.thursday = (props.playlist.weekdays & 16) > 0
    form.value.friday = (props.playlist.weekdays & 32) > 0
  }
  if (form.value.startTime == "00:00:00" && form.value.endTime == "23:59:59") {
    form.value.allDay = true
  } else {
    form.value.allDay = false
  }
  if (form.value.startDate == null && form.value.endDate == null) {
    form.value.dateSameAsCampaign = true
  } else {
    form.value.dateSameAsCampaign = false
  }
  if (form.value.everday && form.value.allDay && form.value.dateSameAsCampaign) {
    form.value.type = 0
  } else {
    form.value.type = 1
  }
}
function bindModel() {

  if (form.value.type == 0) {
    return {
      weekdays: 127,
      startTime: "00:00:00",
      endTime: "23:59:59",
      startDate: null,
      endDate: null
    }
  } else {
    let weekdays = 0;
    if (form.value.friday) {
      weekdays += 32
    }
    if (form.value.saturday) {
      weekdays += 64
    }
    if (form.value.sunday) {
      weekdays += 1
    }
    if (form.value.monday) {
      weekdays += 2
    }
    if (form.value.tuesday) {
      weekdays += 4
    }
    if (form.value.wednesday) {
      weekdays += 8
    }
    if (form.value.thursday) {
      weekdays += 16
    }
    return {
      weekdays: weekdays,
      startTime: form.value.allDay ? '00:00:00' : form.value.startTime,
      endTime: form.value.allDay ? '23:59:59' : form.value.endTime,
      startDate: form.value.dateSameAsCampaign ? null : form.value.startDate,
      endDate: form.value.dateSameAsCampaign ? null : form.value.endDate
    }
  }


}
function onExit() {
  emit('exit')
}
async function onSave() {
   emit('save', bindModel());
    recurranceModal.value.closeModal()
}

</script>
    
<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>